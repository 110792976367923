import '../styles/index.scss';

const hash = window.location.hash.replace('#', '');
const html = document.querySelector('html');

function navigate(fragment) {
    const newFragmentName = fragment.replace('#', '');

    html.classList.forEach((c) => html.classList.remove(c));
    html.classList.add(`page-${newFragmentName}`);

    const activeFragment = document.querySelector('.fragment.active');
    if (activeFragment) {
        activeFragment.classList.remove('active');
    }

    const newFragment = document.querySelector(`.fragment.${newFragmentName}`);
    if (newFragment) {
        newFragment.classList.add('active');
    }

    menuItems.forEach((i) => i.classList.remove('has-text-weight-bold'));
    const menuItem = menu.querySelector(`[href="#${fragment}"]`);
    if (menuItem) {
        menuItem.classList.add('has-text-weight-bold');
    }
}

const menu = document.querySelector('#menu');
const menuItems = menu.querySelectorAll('a');

window.addEventListener('hashchange', (event) => {
    navigate((new URL(event.newURL)).hash);
});

const title = document.querySelector('h1 a');
if (title) {
    title.addEventListener('click', function (e) {
        navigate(getFragment(e.target.href));
    });
}

function getFragment(url) {
    const parts = url.split('#');
    if (parts.length > 1) {
        return parts.pop();
    }

    throw Error();
}


document.addEventListener("DOMContentLoaded", function () {
    if (hash !== '') {
        navigate(hash);
    }
});
